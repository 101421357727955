import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import PreviewCompatibleImage from './PreviewCompatibleImage';

class BlogRollTemplate extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <div className='blog-roll'>
        {posts &&
          posts.map(({ node: post }) => (
            <Link
              // className={`blog-card ${
              //   post.frontmatter.featuredpost ? 'blog-card__is-featured' : ''
              // }`}
              to={post.fields.slug}
              className='blog-card'
              key={post.id}>
              <div className='blog-card__header-wrapper'>
                <div className='blog-card-image-wrapper'>
                  {post.frontmatter.featuredimage ? (
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: post.frontmatter.featuredimage,
                        alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                        width:
                          post.frontmatter.featuredimage.childImageSharp
                            .gatsbyImageData.width,
                        height:
                          post.frontmatter.featuredimage.childImageSharp
                            .gatsbyImageData.height,
                      }}
                    />
                  ) : null}
                </div>

                {/* <Link className='blog-card__title' to={post.fields.slug}> */}
                <h3 className='blog-card__title'>{post.frontmatter.title}</h3>
                {/* </Link> */}
                <span className='blog-card__date'>{post.frontmatter.date}</span>
                <p className='blog-card__article'>{post.excerpt}</p>
              </div>
              <div className='blog-card__button-wrapper'>
                <Link className='blog-card__button' to={post.fields.slug}>
                  Keep Reading →
                </Link>
              </div>
            </Link>
          ))}
      </div>
    );
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default function BlogRoll() {
  return (
    <StaticQuery
      query={graphql`
        query BlogRollQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
          ) {
            edges {
              node {
                excerpt(pruneLength: 400)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  templateKey
                  date(formatString: "MMMM DD, YYYY")
                  featuredpost
                  featuredimage {
                    childImageSharp {
                      gatsbyImageData(
                        width: 120
                        quality: 100
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <BlogRollTemplate data={data} count={count} />}
    />
  );
}
